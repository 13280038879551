import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/33cbcb6e/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "neoanalytics-"
    }}>{`NeoAnalytics 👩‍🎤`}</h1>
    <p>{`NeoAnalytics ist eine leichtgewichtige, DSGVO konforme Alternative zu Google Analytics.`}</p>
    <p>{`Unsere Seiten:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://neoanalytics.de"
        }}>{`NeoAnalytics`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://app.neoanalytics.de"
        }}>{`Zum Dashboard`}</a></li>
    </ul>
    <h2 {...{
      "id": "integration"
    }}>{`Integration`}</h2>
    <p>{`Um NeoAnalytics in Ihrer Website zu integrieren, fügen Sie einfach das folgende Script-Tag am Ende ihres `}<inlineCode parentName="p">{`head`}</inlineCode>{` ein.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-HTML"
      }}>{`<script async defer src="https://neoanalytics.de/neoClient.js"></script>
`}</code></pre>
    <p>{`NeoAnalytics übernimmt nun automatisch das anonyme sammeln von Besuchen ihrer Website. Dabei speichern wir niemals Daten über Nutzer und betreiben kein Fingerprinting.`}</p>
    <p>{`Mehr Optionen zum Einstellen von NeoAnalytics finden Sie unter `}<a parentName="p" {...{
        "href": "/configuration"
      }}>{`Konfiguration`}</a>{`.`}</p>
    <p>{`Durch die Nutzung von `}<inlineCode parentName="p">{`async defer`}</inlineCode>{` im NeoAnalytics Script-Tag wird NeoAnalytics im Hintergrund geladen und unabhängig von Ihrer Seite ausgeführt, sobald es bereit ist.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      